import React from 'react'
import { graphql } from 'gatsby'

import { getDateRangeArr } from '../lib/helpers'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../components/layout'

import LinkPanel from '../mui-components/LinkPanel/LinkPanel'

export const query = graphql`
  query CasesQuery {
    cases: allSanityCases(sort: { order: ASC, fields: title }) {
      nodes {
        id
        title
        path
        startDate(formatString: "YYYY")
        endDate(formatString: "YYYY")
        countryIcon {
          iconSource
        }
        metaDescription
        metaTitle
      }
    }
  }
`

const CasesPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const cases = data.cases.nodes.map(node => {
    return (
      <LinkPanel
        title={node.title}
        subtitle={getDateRangeArr(node.startDate, node.endDate)}
        to={node.path}
        key={node.id}
        icon={node.countryIcon}
      />
    )
  })

  const title = 'Cases & episodes'

  return (
    <Layout title={title} {...props}>
      <SEO title={title} />
      {cases}
    </Layout>
  )
}

export default CasesPage
