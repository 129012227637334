import React from 'react'
import Link from 'gatsby-link'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import CustomSVG from '../../components/custom-svg'

const styles = makeStyles(theme => ({
  root: {
    padding: props => (props.noShadow ? theme.spacing(1) : theme.spacing(1.75)),
    paddingRight: props => (props.noShadow ? 0 : theme.spacing(1.5)),
    paddingLeft: props => (props.noShadow ? 0 : theme.spacing(1.5)),
    boxShadow: props => (!props.noShadow ? theme.shadows[3] : 'none'),
    '&:last-child': {
      marginBottom: 0
    },
    // Add margin when we are showing multiple panels
    '& + .MuiPaper-root': {
      marginTop: props => (props.noShadow ? 0 : theme.spacing(2))
    }
  },

  titleRoot: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.25)
  },

  linkIcon: {
    marginRight: theme.spacing(1),
    '& svg': {
      width: '35px',
      height: '35px',
      display: 'block'
    }
  },

  linkIconBg: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: theme.spacing(1),
    background: theme.palette.secondary.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0.75),
    width: '38px',
    height: '38px',
    color: theme.palette.primary.main,

    '& svg': {
      maxWidth: '23px',
      maxHeight: '23px',

      '& path': {
        fill: theme.palette.primary.main
      }
    }
  },

  linkWrapper: {
    marginBottom: '-2px',
    overflow: 'hidden'
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '32px',
    color: 'inherit',
    textDecoration: 'none'
  },

  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },

  subtitle: {
    color: theme.palette.grey[500],
    display: 'block'
  },

  linkIconGo: {
    color: theme.palette.primary.main,
    marginLeft: 'auto',
    marginRight: 0,
    width: '24px',
    height: '24px',
    boxSizing: 'content-box',
    paddingLeft: theme.spacing(2)
  },

  tag: {
    display: 'inline-block',
    background: props => props.tagColor,
    borderRadius: '15px',
    paddingTop: '4px',
    paddingRight: '8px',
    paddingBottom: '3px',
    paddingLeft: '8px',
    marginLeft: '10px',
    color: theme.palette.primary.contrastText,
    fontSize: '10px'
  }
}))

export default function linkPanel(props) {
  const classes = styles(props)
  const icon = props.icon ? <CustomSVG content={props.icon.iconSource} /> : null

  return (
    <Paper className={classes.root + ' ' + props.className}>
      <Link to={props.to} onClick={props.onClick} className={classes.link}>
        {icon ? (
          <div className={classes.linkIcon + ' ' + (props.iconBg ? classes.linkIconBg : null)}>
            {icon}
          </div>
        ) : null}

        <div className={classes.linkWrapper}>
          <div className={classes.titleRoot}>
            <Typography variant="h6" className={classes.title}>
              {props.title}
            </Typography>
            {props.tag ? <span className={classes.tag}>{props.tag}</span> : null}
          </div>
          {props.subtitle ? (
            <Typography variant="caption" component="div" className={classes.subtitle}>
              {props.subtitle}
            </Typography>
          ) : null}
        </div>

        <NavigateNextIcon className={classes.linkIconGo} />
      </Link>
    </Paper>
  )
}
